import log from '../../services/log';
import { PLAYER_STATE_EVENTS } from './constants';

export const generateConfigFromTracks = (
  suppliedTracks = [],
  additionalConfig = {},
) => ({
  youtube: {
    playerVars: {
      controls: 1,
      modestbranding: 1,
      loop: 0,
      rel: 0,
    },
  },
  vimeo: {
    playerOptions: {
      controls: 1,
      title: 0,
      byline: 0,
      portrait: 0,
      speed: 0,
      badge: 0,
      autopause: 0,
      muted: 0,
      responsive: 1,
    },
  },
  ...additionalConfig,
  file: {
    attributes: { crossOrigin: 'anonymous' },
    tracks: suppliedTracks,
  },
});

export const handleToggleMuted = (muted, setMuted) => {
  setMuted(!muted);
};

export const handleTogglePlaying = (playState, setPlayState) => {
  setPlayState(!playState);
};

export const handleAutoPlay = async (
  autoPlay,
  initialMuted,
  playerRef,
  setPlaying,
  setMuted,
) => {
  const autoPlayDesired = autoPlay ?? true;
  if (playerRef && autoPlayDesired) {
    const videoInstance = playerRef.getInternalPlayer();
    // if we are wanting to autoplay, we need to be muted
    try {
      // Autoplay started!
      await videoInstance.play();
      setPlaying(true);
    } catch (e) {
      log.info('default auto play failed', videoInstance.muted);
      // Autoplay not allowed!
      // Mute video and try to play again
      videoInstance.muted = true;
      setMuted(true);
      try {
        log.info('auto play after muting');
        await videoInstance.play();
        setPlaying(true);
      } catch (e2) {
        log.info('even muted autoplay isnt working');
        // restore unmuted state
        videoInstance.muted = initialMuted ?? false;
        setMuted(initialMuted ?? false);
      }
    }
  }
};
