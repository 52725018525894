import PropTypes from 'prop-types';

import Pause from '../../Icons/Pause';
import Play from '../../Icons/Play';
import Skip from '../../Icons/Skip';
import VolumeOff from '../../Icons/VolumeOff';
import VolumeOn from '../../Icons/VolumeOn';
import usePlayerControls from './hooks/usePlayerControls';
import { CONTROLS } from './constants';
import useStyles from './playerControls.styles';

const PlayerControls = ({
  controls,
  muted,
  onSkip,
  showCC,
  playing,
  toggleMuted,
  stopPlaying,
  togglePlaying,
}) => {
  const styles = useStyles();
  const { onSkipButtonClick } = usePlayerControls(onSkip, stopPlaying);

  return (
    <div className={styles.wrapper}>
      {controls[CONTROLS.PLAY_PAUSE] && (
        <button type="button" className={styles.button} onClick={togglePlaying}>
          {playing ? (
            <Pause className={styles.buttonIcon} />
          ) : (
            <Play className={styles.buttonIcon} />
          )}
        </button>
      )}
      {/* toggle mute button */}
      {controls[CONTROLS.VOLUME] && (
        <button type="button" className={styles.button} onClick={toggleMuted}>
          {muted ? (
            <VolumeOff className={styles.buttonIcon} />
          ) : (
            <VolumeOn className={styles.buttonIcon} />
          )}
        </button>
      )}
      {/* skip button */}
      {controls[CONTROLS.SKIP] && (
        <button
          type="button"
          className={styles.button}
          onClick={onSkipButtonClick}
        >
          <Skip className={styles.buttonIcon} />
        </button>
      )}
    </div>
  );
};

PlayerControls.propTypes = {
  controls: PropTypes.shape({
    skip: PropTypes.bool,
    play_pause: PropTypes.bool,
    volume: PropTypes.bool,
  }),
  muted: PropTypes.bool.isRequired,
  onSkip: PropTypes.func,
  playing: PropTypes.bool.isRequired,
  showCC: PropTypes.bool.isRequired,
  toggleMuted: PropTypes.func,
  stopPlaying: PropTypes.func.isRequired,
  togglePlaying: PropTypes.func,
};

PlayerControls.defaultProps = {
  controls: undefined,
  onSkip: undefined,
  toggleMuted: undefined,
  togglePlaying: undefined,
};

export default PlayerControls;
