import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactPlayer from 'react-player/lazy';

import LoadWhenInView from '../LoadWhenInView';
import useMediaPlayerControls from './hooks/useMediaPlayerControls';
import useStyles from './mediaPlayer.styles';
import PlayerCaptions from './PlayerCaptions';
import PlayerControls from './PlayerControls';

const MediaPlayer = ({
  className,
  additionalConfig,
  children,
  controls,
  isClickable,
  initialState,
  loop,
  onSkip,
  onComplete,
  onRegisterPlayer,
  onStateChange,
  sources,
  tracks,
  trackIndex,
  withOverlay,
}) => {
  const styles = useStyles();
  const {
    config,
    muted,
    onReady,
    onPlay,
    handleSkip,
    handleComplete,
    playerRef,
    playing,
    showCC,
    stopPlaying,
    toggleMuted,
    togglePlaying,
  } = useMediaPlayerControls({
    initialState,
    tracks,
    additionalConfig,
    onStateChange,
    onRegisterPlayer,
    onSkip,
    onComplete,
  });

  return (
    <div className={clsx(className, styles.wrapper)}>
      {(controls || isClickable) && (
        <div className={styles.playPauseOverlay} onClick={togglePlaying} />
      )}
      {withOverlay && <div className={styles.gradientOverlay} />}
      <LoadWhenInView className={styles.videoPlayerWrapper}>
        <ReactPlayer
          ref={playerRef}
          className={styles.videoPlayer}
          config={config}
          height=""
          loop={loop}
          muted={muted}
          onEnded={handleComplete}
          onReady={onReady}
          onPlay={onPlay}
          onPause={stopPlaying}
          playing={playing}
          playsinline
          url={sources}
          width=""
        />
      </LoadWhenInView>
      <PlayerCaptions
        active={showCC}
        playerRef={playerRef?.current}
        muted={muted}
        tracks={tracks}
        trackIndex={trackIndex}
      />
      {controls && (
        <PlayerControls
          controls={controls}
          muted={muted}
          onSkip={handleSkip}
          playing={playing}
          showCC={showCC}
          stopPlaying={stopPlaying}
          toggleMuted={toggleMuted}
          togglePlaying={togglePlaying}
        />
      )}
      {children({
        playing,
        togglePlaying,
      })}
    </div>
  );
};

MediaPlayer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func,

  additionalConfig: PropTypes.object,
  initialState: PropTypes.shape({
    playing: PropTypes.bool,
    muted: PropTypes.bool,
  }),
  loop: PropTypes.bool,
  withOverlay: PropTypes.bool,

  controls: PropTypes.shape({
    skip: PropTypes.bool,
    play_pause: PropTypes.bool,
    volume: PropTypes.bool,
  }),
  isClickable: PropTypes.bool,
  onSkip: PropTypes.func,
  onRegisterPlayer: PropTypes.func,
  onComplete: PropTypes.func,
  onStateChange: PropTypes.func,

  sources: PropTypes.any.isRequired,
  tracks: PropTypes.any,
  trackIndex: PropTypes.number,
};

MediaPlayer.defaultProps = {
  className: undefined,
  additionalConfig: undefined,
  children: () => null,
  withOverlay: true,
  initialState: {
    playing: true,
    muted: false,
  },
  controls: undefined,
  onSkip: undefined,
  onComplete: () => null,
  onRegisterPlayer: () => null,
  onStateChange: () => null,
  isClickable: false,
  loop: false,
  tracks: undefined,
  trackIndex: 0,
};

export default MediaPlayer;
