export const CONTROLS = {
  SKIP: 'skip',
  PLAY_PAUSE: 'play_pause',
  VOLUME: 'volume',
};

export const CONTROLS_ALL = {
  [CONTROLS.PLAY_PAUSE]: true,
  [CONTROLS.VOLUME]: true,
  [CONTROLS.SKIP]: true,
};

export const CONTROLS_MINIMAL = {
  [CONTROLS.VOLUME]: true,
};
